<template>
  <div>
    <b-row class="card mt-5">
      <div class="w-100">
        <h5 class="title-card">
          <i class="fas fa-user"></i> Usuários Cadastrados
        </h5>
      </div>

      <div class="w-100 p-3">
        <b-button variant="primary" v-b-modal.new-user>
          <i class="fas fa-plus"></i> Adicionar novo usuário
        </b-button>
      </div>

      <div class="w-100 p-3">
        <b-table
        responsive
        bordered
        :busy="load"
        :items="users"
        :fields="columsTableUsers"
        >
        <template #table-busy>
          <div class="text-center text-success my-2">
            <b-spinner class="align-middle"></b-spinner>
            <strong>Carregando...</strong>
          </div>
        </template>
        <template v-slot:cell(actions)="data">
          <b-button
          v-b-tooltip.hover
          size="sm"
          title="Editar"
          class="mr-2"
          @click="getUser(data.item.id)"
          variant="warning">
            <i class="fas fa-pen-square"></i>
          </b-button>
        </template>
        </b-table>
      </div>
    </b-row>

    <b-modal
    ref="add-user"
    cancel-title="Fechar"
    id="new-user"
    @hide="reset"
    title="Cadastrar novo usuário">
      <b-row>
        <b-col lg="12">
          <b-form-group label="Nome">
            <b-form-input
            :class="{ 'form-group--error': $v.payload.name.$error }"
            v-model="$v.payload.name.$model"
            :state="$v.payload.name.$dirty ?
            !$v.payload.name.$error : null">
              </b-form-input>
          </b-form-group>
        </b-col>

        <b-col lg="12">
          <b-form-group label="Perfil de usuário">
            <b-form-select
             :class="{ 'form-group--error': $v.payload.type.$error }"
            v-model="$v.payload.type.$model"
            :state="$v.payload.type.$dirty ?
            !$v.payload.type.$error : null"
            :options="typeUsers"></b-form-select>
          </b-form-group>
        </b-col>

        <b-col lg="12">
          <b-form-group label="Email">
            <b-form-input
            :class="{ 'form-group--error': $v.payload.email.$error }"
            v-model="$v.payload.email.$model"
            :state="$v.payload.email.$dirty ?
            !$v.payload.email.$error : null"
            />
          </b-form-group>
        </b-col>

        <b-col lg="12">
          <b-form-group label="Senha">
            <b-form-input
              v-model="payload.password"
            />
          </b-form-group>
        </b-col>

        <b-col lg="12">
          <b-form-group label="Permissões de usuário" v-slot="{ ariaDescribedby }">
            <b-form-checkbox-group
              id="checkbox-group-1"
              v-model="payload.rules"
              :options="rules"
              :aria-describedby="ariaDescribedby"
              name="flavour-1"
            />
          </b-form-group>
        </b-col>
      </b-row>
       <template v-slot:modal-footer>
          <div class="w-100">
            <b-button
              :disabled="spinner"
              variant="primary"
              size="sm"
              class="float-right"
              @click="save"
            >
            <b-spinner small v-show="spinner"></b-spinner>
              Salvar
            </b-button>
          </div>
        </template>

    </b-modal>
  </div>
</template>
<script>
import { required, email } from 'vuelidate/lib/validators';
import MessagesMixin from '@/mixins/messages-mixin';
import QuestionMixin from '@/mixins/question-mixin';
import ErrorMixin from '@/mixins/error-mixin';
import UserService from '@/Services/UserService';

export default {
  data() {
    return {
      columsTableUsers: UserService.getFieldsTableUsers(),
      users: [],
      load: true,
      spinner: false,
      rules: [
        {
          text: 'Agentes',
          value: 'agent',
        },
        {
          text: 'Clientes',
          value: 'client',
        },
        {
          text: 'Projetos',
          value: 'project',
        },
        {
          text: 'Financiamentos',
          value: 'financing',
        },
        {
          text: 'Usuários',
          value: 'user',
        },
        {
          text: 'Administradores',
          value: 'admin',
        },
        {
          text: 'Inversores',
          value: 'inverter',
        },
        {
          text: 'Transformadores',
          value: 'transformer',
        },
        {
          text: 'Painel',
          value: 'panel',
        },
        {
          text: 'Estruturas',
          value: 'structure',
        },
        {
          text: 'Componentes',
          value: 'component',
        },
      ],
      typeUsers: [
        {
          text: 'Selecione',
          value: '',
        },
        {
          text: 'Agente',
          value: 'public',
        },
        {
          text: 'Interno',
          value: 'internal',
        },
        {
          text: 'Administrador',
          value: 'admin',
        },
      ],
      payload: {
        id: '',
        name: '',
        email: '',
        password: '',
        type: '',
        rules: [],
      },
    };
  },

  validations: {
    payload: {
      name: { required },
      email: { required, email },
      type: { required },
    },
  },
  mixins: [MessagesMixin, QuestionMixin, ErrorMixin],
  methods: {
    getTypeUser(type) {
      let perfil = '';
      switch (type) {
        case 'public':
          perfil = 'Agente';
          break;
        case 'internal':
          perfil = 'Interno';
          break;
        case 'admin':
          perfil = 'Administrador';
          break;
        default: perfil = '';
          break;
      }
      return perfil;
    },
    async getAll() {
      this.load = true;

      const { data } = await UserService.getList();
      this.users = data.map((item) => ({
        id: item.id,
        name: item.name,
        email: item.email,
        type: this.getTypeUser(item.type),
      }));

      this.load = false;
    },

    async getUser(id) {
      const { data } = await UserService.get(id);
      this.payload = { ...data };
      this.payload.rules = data.rules;
      console.log(this.payload);
      this.$nextTick(() => {
        this.$refs['add-user'].show();
      });
    },

    reset() {
      this.$v.payload.$reset();
      this.payload = {
        id: '',
        name: '',
        email: '',
        password: '',
      };
    },

    async save() {
      let { data } = {};

      this.$v.payload.$touch();

      if (!this.$v.payload.$invalid) {
        this.spinner = true;

        try {
          if (this.payload.id) {
            data = await UserService.edit(this.payload);
            this.messageSuccess(`O usuário ${data.name} foi editado com sucesso`);
          } else {
            data = await UserService.save(this.payload);
            this.messageSuccess(`O usuário ${data.name} foi cadastrado com sucesso`);
          }

          this.$nextTick(() => {
            this.$refs['add-user'].hide();
          });

          this.getAll();
        } catch (error) {
          console.log(error);
          this.messageError('Não foi possível realizar o cadastro, tente novamente ou entre contato com o administrador');
        }
      } else {
        this.messageError('Preencha os campos obrigatórios');
      }

      this.spinner = false;
    },
  },
  created() {
    this.getAll();
  },
};
</script>
